<template>
  <swiper
    :spaceBetween="0"
    :centeredSlides="true"
    :pagination="{
      clickable: true,
    }"
    :navigation="true"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide><img src="@/images/numbers/erchim/1.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/2.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/3.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/4.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/5.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/6.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/7.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/8.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/9.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/10.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/11.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/12.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/13.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/14.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/15.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/16.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/17.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/18.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/19.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/20.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/21.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/22.jpeg" class="card-gallery"></swiper-slide>
    <swiper-slide><img src="@/images/numbers/erchim/23.jpeg" class="card-gallery"></swiper-slide>
  </swiper>
</template>
<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/pagination';
  import 'swiper/css/navigation';


  // import required modules
  import { Autoplay, Pagination, Navigation } from 'swiper/modules';

  export default {
    name: 'v-card-gallery',
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Autoplay, Pagination, Navigation],
      };
    },
  };
</script>

<style>
.card-gallery{
    width: 100%;
    aspect-ratio: 16 / 8;
    object-fit: cover;
}


@media screen and (max-width: 700px) {
    .card-gallery{
        aspect-ratio: 3 / 3;
        object-fit: cover;
    }
}
</style>
